// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        align-items: center;
        justify-content: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 2rem;
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            & p {
                font-size: 3rem;
                font-weight: 400;
                line-height: 1.5;
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledOurServices = styled.div`
    #web-ontwerp {
        background-image: url(${props => !!props.webOntwerpImage.childImageSharp ? props.webOntwerpImage.childImageSharp.fluid.src : props.webOntwerpImage});
    }
    #webwinkels {
        background-image: url(${props => !!props.webwinkelImage.childImageSharp ? props.webwinkelImage.childImageSharp.fluid.src : props.webwinkelImage});
    }
    #web-applicaties {
        background-image: url(${props => !!props.webApplicatiesImage.childImageSharp ? props.webApplicatiesImage.childImageSharp.fluid.src : props.webApplicatiesImage});
    }
    #online-adverteren {
        background-image: url(${props => !!props.onlineAdverterenImage.childImageSharp ? props.onlineAdverterenImage.childImageSharp.fluid.src : props.onlineAdverterenImage});
    }

    & .row {
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;

        &--1 .content {
            clip-path: polygon(0 0, 75% 0, 50% 100%, 0% 100%);
            padding-right: 50%;
            background-color: var(--color-background-2);

            @media only screen and (max-width: 800px) {
                padding: var(--padding-right);
                padding-bottom: 50rem;
                clip-path: polygon(0 0,100% 0,100% calc(100% - 50rem),0 calc(100% - 40rem));
            }
        }

        &--2 .content {
            clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 50% 100%);
            padding-left: 50%;

            @media only screen and (max-width: 800px) {
                padding: var(--padding-right);
                padding-bottom: 50rem;
                clip-path: polygon(0 0,100% 0,100% calc(100% - 40rem),0 calc(100% - 50rem));

                &-link {
                    justify-self: end;
                }
            }
        }

        &--3 {
            & .content {
                background-color: var(--color-background-1);
                color: #fff;
                grid-template-columns: 75% 1fr;
                grid-row-gap: 1rem;

                @media only screen and (max-width: 950px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(3, min-content);
                    grid-row-gap: 5rem;
                }

                & h1 {
                    font-size: 5rem;
                    font-weight: var(--font-weight-body-h1);
                    line-height: var(--line-height-body-h1);
                }

                & h2 {
                    font-size: 3rem;
                    font-weight: 100;
                    line-height: var(--line-height-body-h2);
                    color: var(--color-primary);

                    @media only screen and (max-width: 950px) {
                        margin-top: -4rem;
                    }
                }

                &-link {
                    grid-column: 2 / 3;
                    grid-row: 1 / 3;
                    align-self: center;
                    justify-self: center;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    background-color: var(--color-primary);
                    border: 2px solid var(--color-primary);
                    border-radius: 0;
                    font-size: 2rem;
                    padding: 1rem 2rem;
                    color: #fff;
                    font-weight: 400;
                    text-decoration: none;

                    & span {
                        margin-right: 10px;
                    }

                    @media only screen and (max-width: 950px) {
                        grid-column: 1 / -1;
                        grid-row: 3 / 4;
                        justify-self: start;
                    }
                }

                &-link:link,
                &-link:visited {
                    color: currentColor;
                    text-decoration: none;
                    outline: 0;
                    transition: color .25s ease;
                }

                &-link:hover,
                &-link:focus {
                    text-decoration: none;
                    color: currentColor;
                    outline: 0;
                    transform: translateY(-3px);
                    box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
                }

                &-link:active {
                    text-decoration: none;
                    color: currentColor;
                    outline: 0;
                    transform: translateY(0);
                    box-shadow: none;
                }
            }
        }
    }

    & .content {
        display: grid;
        grid-row-gap: 3rem;
        background-color: #fff;
        padding: var(--padding-right);

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & p {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        &-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        &-link:link,
        &-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;


// Create component
const OurServicesPage = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/onze-diensten/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            webOntwerpImage: file(relativePath: { eq: "onze-diensten/Web-ontwerp.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webwinkelImage: file(relativePath: { eq: "onze-diensten/Webwinkel.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webApplicatiesImage: file(relativePath: { eq: "onze-diensten/Web-applicaties.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            onlineAdverterenImage: file(relativePath: { eq: "onze-diensten/Online-ads-editted.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Onze Diensten" />
            <Layout withBanner={true}>
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1>Wat wij te bieden hebben</h1>
                            <p>Wij <span className="primary-color">specialiseren</span> ons in web ontwerp, webwinkels, web applicaties, online adverteren, online vindbaarheid, aantrekkelijke inhoud, de beste beveiliging, en optimale hosting voor al onze <span className="primary-color">producten</span>. Hieronder vindt u meer informatie over <span className="primary-color">onze diensten</span>.</p>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>

                <StyledOurServices
                    webOntwerpImage={data.webOntwerpImage}    
                    webwinkelImage={data.webwinkelImage}    
                    webApplicatiesImage={data.webApplicatiesImage}    
                    onlineAdverterenImage={data.onlineAdverterenImage}    
                >
                    <div className="row row--1" id="web-ontwerp">
                        <div className="content">
                            <h1>Web Ontwerp</h1>

                            <p>Al onze producten worden op maat ontworpen naar de wensen van de klant. Alles is daarom mogelijk bij <span className="primary-color">boumedia</span>. Aan de hand van een ori&euml;ntatie gesprek noteren wij uw wensen en gaan wij aan de slag met het ontwerpen van uw op maat gemaakte website.</p>

                            <p>De juiste content zorgt ervoor dat uw website tot leven komt. Denk hierbij aan professionele foto&apos;s, video&apos;s en een sterke tekst zodat de bezoekers van uw website een duidelijk beeld krijgen bij uw onderneming.</p>

                            <p>Daarnaast ontwerpen wij uw website met de laatste technologie&euml;n en zorgen wij ervoor dat uw website goed tot zijn recht zal komen op de verschillende beeldschermen. Het mobiele verkeer zal voor het grootste aantal bezoekers op uw website zorgen, een responsive web ontwerp is daarom van essentieel belang.</p>

                            <p>Wij werken net zo lang aan uw web ontwerp totdat u 100% tevreden bent. Daarna zullen wij over gaan tot het ontwikkelen van uw op maat gemaakte website.</p>

                            <Link to="/contact" className="content-link">
                                <span>Neem vrijblijvend contact op</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--2" id="webwinkels">
                        <div className="content">
                            <h1>Webwinkels</h1>

                            <p>Online winkelen is de laatste jaren alleen maar meer en meer gestegen. Wanneer u fysieke producten verkoopt kunt u haast niet meer zonder een webwinkel. De laatste technologie&euml;n omtrent het ontwikkelen van een webwinkel zijn daarom ook steeds meer toegankelijk en zorgt ervoor dat een basis webwinkel heel eenvoudig gerealiseerd kan worden. Dit maakt het daarom ook steeds aantrekkelijker voor start-ups om een webwinkel te openen.</p>

                            <p><span className="primary-color">Boumedia</span> heeft de afgelopen jaren verschillende webwinkels in diverse landen succesvol gerealiseerd. Veel van deze webwinkels hebben hun assortiment inmiddels flink uitgebreid. Door onze kennis in online marketing zorgen wij ervoor dat uw producten bij de juiste doelgroep naar voren komen.</p>

                            <p>Zit u aan een webwinkel te denken? Neem dan gerust vrijblijvend contact met ons op zodat wij met u mee kunnen denken over de mogelijkheden om uw webwinkel te realiseren. Heeft u al een bestaande webwinkel maar wilt u uw webwinkel een nieuwe en frisse look geven? Ook hier kan <span className="primary-color">boumedia</span> u mee van dienst zijn.</p>

                            <Link to="/contact" className="content-link">
                                <span>Neem vrijblijvend contact op</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--3">
                        <div className="content">
                            <h1>Stelt u belang in &eacute;&eacute;n van onze diensten voor uw onderneming?</h1>
                            <h2>Wij komen graag persoonlijk bij u langs om alles vrijblijvend door te nemen.</h2>

                            <Link to="/afspraak-maken" className="content-link">
                                <span>Maak een afspraak</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--1" id="web-applicaties">
                        <div className="content">
                            <h1>Web Applicaties</h1>

                            <p>Iedere werknemer heeft zijn of haar eigen taken binnen het bedrijf. Als ondernemer bent u op een dagelijkse basis te druk bezig om het bedrijf te laten groeien. Daarom snappen wij dat u geen tijd heeft om alle bedrijfsprocessen in uw onderneming te analyseren en te kijken waar het geoptimaliseerd kan worden.</p>

                            <p>Wij bij <span className="primary-color">boumedia</span> specialiseren ons erin om uw bedrijfsprocessen te analyseren en in samenwerking met het personeel in uw bedrijf kunnen wij identificeren welke processen geautomatiseerd kunnen worden doormiddel van een web applicatie. Doormiddel van het implementeren van een web applicatie kunnen dagelijkse taken veel effci&euml;nter uitgevoerd worden en kunnen handmatige taken gedigitaliseerd worden. Tevens kunnen statistieken en grafieken uw helpen om uw ondernemimg te laten groeien en bepaalde besluiten makkelijker te nemen.</p>

                            <p>Als u geintereseerd bent kunnen wij een vrijblijvende analyse uitvoeren en met een voorstel komen.</p>

                            <Link to="/contact" className="content-link">
                                <span>Neem vrijblijvend contact op</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--2" id="online-adverteren">
                        <div className="content">
                            <h1>Online Adverteren</h1>

                            <p>In het huidige digitale tijdperk zijn er diverse mogelijkheden om uw product of diensten aan de gewenste doelgroep weer te laten geven. Wij laten u graag vrijblijvend de verschillende mogelijkheden zien hoe wij uw digitale aanwezigheid kunnen verbreden.</p>

                            <p>Een aantal voorbeelden om uw digitale aanwezigheid te verbreden zijn social media marketing, Google advertenties, zoek resultaten optimaliseren en email marketing. Met een concrete digitale strategie kunnen wij potentiele klanten maar ook huidige klanten bereiken en uw product en/of diensten op de voorgrond brengen bij de juiste doelgroep.</p>

                            <Link to="/contact" className="content-link">
                                <span>Neem vrijblijvend contact op</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>
                </StyledOurServices>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default OurServicesPage;